var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var module = {
  exports: exports
};

(function (root, factory) {
  // Hack to make all exports of this module sha256 function object properties.
  var exports = {};
  factory(exports);
  var sha256 = exports["default"];

  for (var k in exports) {
    sha256[k] = exports[k];
  }

  if (typeof module.exports === "object") {
    module.exports = sha256;
  } else root.sha256 = sha256;
})(exports, function (exports) {
  "use strict";

  exports.__esModule = true; // SHA-256 (+ HMAC and PBKDF2) for JavaScript.
  //
  // Written in 2014-2016 by Dmitry Chestnykh.
  // Public domain, no warranty.
  //
  // Functions (accept and return Uint8Arrays):
  //
  //   sha256(message) -> hash
  //   sha256.hmac(key, message) -> mac
  //   sha256.pbkdf2(password, salt, rounds, dkLen) -> dk
  //
  //  Classes:
  //
  //   new sha256.Hash()
  //   new sha256.HMAC(key)
  //

  exports.digestLength = 32;
  exports.blockSize = 64; // SHA-256 constants

  var K = new Uint32Array([1116352408, 1899447441, 3049323471, 3921009573, 961987163, 1508970993, 2453635748, 2870763221, 3624381080, 310598401, 607225278, 1426881987, 1925078388, 2162078206, 2614888103, 3248222580, 3835390401, 4022224774, 264347078, 604807628, 770255983, 1249150122, 1555081692, 1996064986, 2554220882, 2821834349, 2952996808, 3210313671, 3336571891, 3584528711, 113926993, 338241895, 666307205, 773529912, 1294757372, 1396182291, 1695183700, 1986661051, 2177026350, 2456956037, 2730485921, 2820302411, 3259730800, 3345764771, 3516065817, 3600352804, 4094571909, 275423344, 430227734, 506948616, 659060556, 883997877, 958139571, 1322822218, 1537002063, 1747873779, 1955562222, 2024104815, 2227730452, 2361852424, 2428436474, 2756734187, 3204031479, 3329325298]);

  function hashBlocks(w, v, p, pos, len) {
    var a, b, c, d, e, f, g, h, u, i, j, t1, t2;

    while (len >= 64) {
      a = v[0];
      b = v[1];
      c = v[2];
      d = v[3];
      e = v[4];
      f = v[5];
      g = v[6];
      h = v[7];

      for (i = 0; i < 16; i++) {
        j = pos + i * 4;
        w[i] = (p[j] & 255) << 24 | (p[j + 1] & 255) << 16 | (p[j + 2] & 255) << 8 | p[j + 3] & 255;
      }

      for (i = 16; i < 64; i++) {
        u = w[i - 2];
        t1 = (u >>> 17 | u << 32 - 17) ^ (u >>> 19 | u << 32 - 19) ^ u >>> 10;
        u = w[i - 15];
        t2 = (u >>> 7 | u << 32 - 7) ^ (u >>> 18 | u << 32 - 18) ^ u >>> 3;
        w[i] = (t1 + w[i - 7] | 0) + (t2 + w[i - 16] | 0);
      }

      for (i = 0; i < 64; i++) {
        t1 = (((e >>> 6 | e << 32 - 6) ^ (e >>> 11 | e << 32 - 11) ^ (e >>> 25 | e << 32 - 25)) + (e & f ^ ~e & g) | 0) + (h + (K[i] + w[i] | 0) | 0) | 0;
        t2 = ((a >>> 2 | a << 32 - 2) ^ (a >>> 13 | a << 32 - 13) ^ (a >>> 22 | a << 32 - 22)) + (a & b ^ a & c ^ b & c) | 0;
        h = g;
        g = f;
        f = e;
        e = d + t1 | 0;
        d = c;
        c = b;
        b = a;
        a = t1 + t2 | 0;
      }

      v[0] += a;
      v[1] += b;
      v[2] += c;
      v[3] += d;
      v[4] += e;
      v[5] += f;
      v[6] += g;
      v[7] += h;
      pos += 64;
      len -= 64;
    }

    return pos;
  } // Hash implements SHA256 hash algorithm.


  var Hash =
  /** @class */
  function () {
    function Hash() {
      (this || _global).digestLength = exports.digestLength;
      (this || _global).blockSize = exports.blockSize; // Note: Int32Array is used instead of Uint32Array for performance reasons.

      (this || _global).state = new Int32Array(8); // hash state

      (this || _global).temp = new Int32Array(64); // temporary state

      (this || _global).buffer = new Uint8Array(128); // buffer for data to hash

      (this || _global).bufferLength = 0; // number of bytes in buffer

      (this || _global).bytesHashed = 0; // number of total bytes hashed

      (this || _global).finished = false; // indicates whether the hash was finalized

      this.reset();
    } // Resets hash state making it possible
    // to re-use this instance to hash other data.


    Hash.prototype.reset = function () {
      (this || _global).state[0] = 1779033703;
      (this || _global).state[1] = 3144134277;
      (this || _global).state[2] = 1013904242;
      (this || _global).state[3] = 2773480762;
      (this || _global).state[4] = 1359893119;
      (this || _global).state[5] = 2600822924;
      (this || _global).state[6] = 528734635;
      (this || _global).state[7] = 1541459225;
      (this || _global).bufferLength = 0;
      (this || _global).bytesHashed = 0;
      (this || _global).finished = false;
      return this || _global;
    }; // Cleans internal buffers and re-initializes hash state.


    Hash.prototype.clean = function () {
      for (var i = 0; i < (this || _global).buffer.length; i++) {
        (this || _global).buffer[i] = 0;
      }

      for (var i = 0; i < (this || _global).temp.length; i++) {
        (this || _global).temp[i] = 0;
      }

      this.reset();
    }; // Updates hash state with the given data.
    //
    // Optionally, length of the data can be specified to hash
    // fewer bytes than data.length.
    //
    // Throws error when trying to update already finalized hash:
    // instance must be reset to use it again.


    Hash.prototype.update = function (data, dataLength) {
      if (dataLength === void 0) {
        dataLength = data.length;
      }

      if ((this || _global).finished) {
        throw new Error("SHA256: can't update because hash was finished.");
      }

      var dataPos = 0;
      (this || _global).bytesHashed += dataLength;

      if ((this || _global).bufferLength > 0) {
        while ((this || _global).bufferLength < 64 && dataLength > 0) {
          (this || _global).buffer[(this || _global).bufferLength++] = data[dataPos++];
          dataLength--;
        }

        if ((this || _global).bufferLength === 64) {
          hashBlocks((this || _global).temp, (this || _global).state, (this || _global).buffer, 0, 64);
          (this || _global).bufferLength = 0;
        }
      }

      if (dataLength >= 64) {
        dataPos = hashBlocks((this || _global).temp, (this || _global).state, data, dataPos, dataLength);
        dataLength %= 64;
      }

      while (dataLength > 0) {
        (this || _global).buffer[(this || _global).bufferLength++] = data[dataPos++];
        dataLength--;
      }

      return this || _global;
    }; // Finalizes hash state and puts hash into out.
    //
    // If hash was already finalized, puts the same value.


    Hash.prototype.finish = function (out) {
      if (!(this || _global).finished) {
        var bytesHashed = (this || _global).bytesHashed;
        var left = (this || _global).bufferLength;
        var bitLenHi = bytesHashed / 536870912 | 0;
        var bitLenLo = bytesHashed << 3;
        var padLength = bytesHashed % 64 < 56 ? 64 : 128;
        (this || _global).buffer[left] = 128;

        for (var i = left + 1; i < padLength - 8; i++) {
          (this || _global).buffer[i] = 0;
        }

        (this || _global).buffer[padLength - 8] = bitLenHi >>> 24 & 255;
        (this || _global).buffer[padLength - 7] = bitLenHi >>> 16 & 255;
        (this || _global).buffer[padLength - 6] = bitLenHi >>> 8 & 255;
        (this || _global).buffer[padLength - 5] = bitLenHi >>> 0 & 255;
        (this || _global).buffer[padLength - 4] = bitLenLo >>> 24 & 255;
        (this || _global).buffer[padLength - 3] = bitLenLo >>> 16 & 255;
        (this || _global).buffer[padLength - 2] = bitLenLo >>> 8 & 255;
        (this || _global).buffer[padLength - 1] = bitLenLo >>> 0 & 255;
        hashBlocks((this || _global).temp, (this || _global).state, (this || _global).buffer, 0, padLength);
        (this || _global).finished = true;
      }

      for (var i = 0; i < 8; i++) {
        out[i * 4 + 0] = (this || _global).state[i] >>> 24 & 255;
        out[i * 4 + 1] = (this || _global).state[i] >>> 16 & 255;
        out[i * 4 + 2] = (this || _global).state[i] >>> 8 & 255;
        out[i * 4 + 3] = (this || _global).state[i] >>> 0 & 255;
      }

      return this || _global;
    }; // Returns the final hash digest.


    Hash.prototype.digest = function () {
      var out = new Uint8Array((this || _global).digestLength);
      this.finish(out);
      return out;
    }; // Internal function for use in HMAC for optimization.


    Hash.prototype._saveState = function (out) {
      for (var i = 0; i < (this || _global).state.length; i++) {
        out[i] = (this || _global).state[i];
      }
    }; // Internal function for use in HMAC for optimization.


    Hash.prototype._restoreState = function (from, bytesHashed) {
      for (var i = 0; i < (this || _global).state.length; i++) {
        (this || _global).state[i] = from[i];
      }

      (this || _global).bytesHashed = bytesHashed;
      (this || _global).finished = false;
      (this || _global).bufferLength = 0;
    };

    return Hash;
  }();

  exports.Hash = Hash; // HMAC implements HMAC-SHA256 message authentication algorithm.

  var HMAC =
  /** @class */
  function () {
    function HMAC(key) {
      (this || _global).inner = new Hash();
      (this || _global).outer = new Hash();
      (this || _global).blockSize = (this || _global).inner.blockSize;
      (this || _global).digestLength = (this || _global).inner.digestLength;
      var pad = new Uint8Array((this || _global).blockSize);

      if (key.length > (this || _global).blockSize) {
        new Hash().update(key).finish(pad).clean();
      } else {
        for (var i = 0; i < key.length; i++) {
          pad[i] = key[i];
        }
      }

      for (var i = 0; i < pad.length; i++) {
        pad[i] ^= 54;
      }

      (this || _global).inner.update(pad);

      for (var i = 0; i < pad.length; i++) {
        pad[i] ^= 54 ^ 92;
      }

      (this || _global).outer.update(pad);

      (this || _global).istate = new Uint32Array(8);
      (this || _global).ostate = new Uint32Array(8);

      (this || _global).inner._saveState((this || _global).istate);

      (this || _global).outer._saveState((this || _global).ostate);

      for (var i = 0; i < pad.length; i++) {
        pad[i] = 0;
      }
    } // Returns HMAC state to the state initialized with key
    // to make it possible to run HMAC over the other data with the same
    // key without creating a new instance.


    HMAC.prototype.reset = function () {
      (this || _global).inner._restoreState((this || _global).istate, (this || _global).inner.blockSize);

      (this || _global).outer._restoreState((this || _global).ostate, (this || _global).outer.blockSize);

      return this || _global;
    }; // Cleans HMAC state.


    HMAC.prototype.clean = function () {
      for (var i = 0; i < (this || _global).istate.length; i++) {
        (this || _global).ostate[i] = (this || _global).istate[i] = 0;
      }

      (this || _global).inner.clean();

      (this || _global).outer.clean();
    }; // Updates state with provided data.


    HMAC.prototype.update = function (data) {
      (this || _global).inner.update(data);

      return this || _global;
    }; // Finalizes HMAC and puts the result in out.


    HMAC.prototype.finish = function (out) {
      if ((this || _global).outer.finished) {
        (this || _global).outer.finish(out);
      } else {
        (this || _global).inner.finish(out);

        (this || _global).outer.update(out, (this || _global).digestLength).finish(out);
      }

      return this || _global;
    }; // Returns message authentication code.


    HMAC.prototype.digest = function () {
      var out = new Uint8Array((this || _global).digestLength);
      this.finish(out);
      return out;
    };

    return HMAC;
  }();

  exports.HMAC = HMAC; // Returns SHA256 hash of data.

  function hash(data) {
    var h = new Hash().update(data);
    var digest = h.digest();
    h.clean();
    return digest;
  }

  exports.hash = hash; // Function hash is both available as module.hash and as default export.

  exports["default"] = hash; // Returns HMAC-SHA256 of data under the key.

  function hmac(key, data) {
    var h = new HMAC(key).update(data);
    var digest = h.digest();
    h.clean();
    return digest;
  }

  exports.hmac = hmac; // Fills hkdf buffer like this:
  // T(1) = HMAC-Hash(PRK, T(0) | info | 0x01)

  function fillBuffer(buffer, hmac, info, counter) {
    // Counter is a byte value: check if it overflowed.
    var num = counter[0];

    if (num === 0) {
      throw new Error("hkdf: cannot expand more");
    } // Prepare HMAC instance for new data with old key.


    hmac.reset(); // Hash in previous output if it was generated
    // (i.e. counter is greater than 1).

    if (num > 1) {
      hmac.update(buffer);
    } // Hash in info if it exists.


    if (info) {
      hmac.update(info);
    } // Hash in the counter.


    hmac.update(counter); // Output result to buffer and clean HMAC instance.

    hmac.finish(buffer); // Increment counter inside typed array, this works properly.

    counter[0]++;
  }

  var hkdfSalt = new Uint8Array(exports.digestLength); // Filled with zeroes.

  function hkdf(key, salt, info, length) {
    if (salt === void 0) {
      salt = hkdfSalt;
    }

    if (length === void 0) {
      length = 32;
    }

    var counter = new Uint8Array([1]); // HKDF-Extract uses salt as HMAC key, and key as data.

    var okm = hmac(salt, key); // Initialize HMAC for expanding with extracted key.
    // Ensure no collisions with `hmac` function.

    var hmac_ = new HMAC(okm); // Allocate buffer.

    var buffer = new Uint8Array(hmac_.digestLength);
    var bufpos = buffer.length;
    var out = new Uint8Array(length);

    for (var i = 0; i < length; i++) {
      if (bufpos === buffer.length) {
        fillBuffer(buffer, hmac_, info, counter);
        bufpos = 0;
      }

      out[i] = buffer[bufpos++];
    }

    hmac_.clean();
    buffer.fill(0);
    counter.fill(0);
    return out;
  }

  exports.hkdf = hkdf; // Derives a key from password and salt using PBKDF2-HMAC-SHA256
  // with the given number of iterations.
  //
  // The number of bytes returned is equal to dkLen.
  //
  // (For better security, avoid dkLen greater than hash length - 32 bytes).

  function pbkdf2(password, salt, iterations, dkLen) {
    var prf = new HMAC(password);
    var len = prf.digestLength;
    var ctr = new Uint8Array(4);
    var t = new Uint8Array(len);
    var u = new Uint8Array(len);
    var dk = new Uint8Array(dkLen);

    for (var i = 0; i * len < dkLen; i++) {
      var c = i + 1;
      ctr[0] = c >>> 24 & 255;
      ctr[1] = c >>> 16 & 255;
      ctr[2] = c >>> 8 & 255;
      ctr[3] = c >>> 0 & 255;
      prf.reset();
      prf.update(salt);
      prf.update(ctr);
      prf.finish(u);

      for (var j = 0; j < len; j++) {
        t[j] = u[j];
      }

      for (var j = 2; j <= iterations; j++) {
        prf.reset();
        prf.update(u).finish(u);

        for (var k = 0; k < len; k++) {
          t[k] ^= u[k];
        }
      }

      for (var j = 0; j < len && i * len + j < dkLen; j++) {
        dk[i * len + j] = t[j];
      }
    }

    for (var i = 0; i < len; i++) {
      t[i] = u[i] = 0;
    }

    for (var i = 0; i < 4; i++) {
      ctr[i] = 0;
    }

    prf.clean();
    return dk;
  }

  exports.pbkdf2 = pbkdf2;
});

export default module.exports;
export const __esModule = module.exports.__esModule,
      digestLength = module.exports.digestLength,
      blockSize = module.exports.blockSize,
      Hash = module.exports.Hash,
      HMAC = module.exports.HMAC,
      hash = module.exports.hash,
      hmac = module.exports.hmac,
      hkdf = module.exports.hkdf,
      pbkdf2 = module.exports.pbkdf2;